<template>
    <div class="active-filters">
        <md-chip
            class="active-filter"
            md-deletable
            md-clickable
            @md-delete="removeFilter(filter.value)"
            v-for="filter in regularFilters"
            :key="filter.value"
        >
            <div>{{ transformFilter(filter.value) }}</div>
        </md-chip>

        <md-chip
            class="active-filter"
            md-deletable
            md-clickable
            @md-delete="removeTag(tag)"
            v-for="tag in activeFilters.tags || []"
            :key="tag"
        >
            <div>#{{ tag }}</div>
        </md-chip>
    </div>
</template>

<script>
export default {
    name: 'ActiveFilters',
    emits: ['remove-filter'],
    props: {
        activeFilters: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    computed: {
        regularFilters() {
            return Object.entries(this.activeFilters)
                .filter(([key]) => key !== 'tags')
                .map(([key, value]) => ({ key, value }));
        },
    },
    methods: {
        transformFilter(filter) {
            return filter.toLowerCase().replace(/_/g, ' ');
        },
        removeFilter(filter) {
            this.$emit('remove-filter', filter);
        },
        removeTag(tag) {
            this.$emit('remove-filter', [tag]);
        },
    },
};
</script>

<style lang="scss" scoped>
.active-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
}
</style>
