<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'FiltersPresetsList',
    emits: ['modalEvent'],
    data() {
        return {
            selectedPresetId: null,
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters('activeFilters', ['presets']),
        hasPresets() {
            return this.presets.length > 0;
        },
        selectedPreset() {
            return this.presets.find((preset) => preset.id === this.selectedPresetId);
        },
    },
    methods: {
        ...mapActions('activeFilters', ['removePreset', 'applyPreset']),
        ...mapActions('modalStore', ['closeModal']),

        formatFilterLabel(key) {
            const labels = {
                agent: 'Agent',
                sentiment: 'Sentiment',
                tags: 'Tags',
            };
            return labels[key] || key;
        },

        async handleRemovePreset(presetId, event) {
            event.stopPropagation();
            try {
                await this.removePreset(presetId);
                this.$toasted.success('Preset removed successfully', {
                    position: 'bottom-center',
                    duration: 3000,
                });
                if (this.selectedPresetId === presetId) {
                    this.selectedPresetId = null;
                }
            } catch (error) {
                this.$toasted.error('Failed to remove preset', {
                    position: 'bottom-center',
                    duration: 3000,
                });
            }
        },

        async handleApplyPreset() {
            if (!this.selectedPresetId) return;

            this.isLoading = true;
            try {
                await this.applyPreset(this.selectedPresetId);
                this.$toasted.success('Filters applied successfully', {
                    position: 'bottom-center',
                    duration: 3000,
                });
                this.$emit('modalEvent', { eventName: 'preset-applied', eventPayload: '' });
                this.closeModal();
            } catch (error) {
                this.$toasted.error('Failed to apply preset', {
                    position: 'bottom-center',
                    duration: 3000,
                });
            } finally {
                this.isLoading = false;
            }
        },

        selectPreset(presetId) {
            this.selectedPresetId = this.selectedPresetId === presetId ? null : presetId;
        },
    },
};
</script>

<template>
    <div class="presets-list">
        <div class="presets-list__header">
            <md-button @click="closeModal()" class="md-icon-button">
                <md-icon>close</md-icon>
            </md-button>
        </div>

        <md-content class="presets-list__content">
            <div class="presets-list__title-container">
                <span class="material-symbols-outlined presets-list__icon">bookmarks</span>
                <p class="presets-list__title md-title">Filter Lists</p>
            </div>

            <div v-if="!hasPresets" class="presets-list__empty">
                <span class="material-symbols-outlined empty-icon">bookmark_border</span>
                <p>No saved presets</p>
            </div>

            <div v-else class="presets-list__items">
                <div
                    v-for="preset in presets"
                    :key="preset.id"
                    class="preset-item"
                    :class="{ 'preset-item--selected': selectedPresetId === preset.id }"
                    @click="selectPreset(preset.id)"
                >
                    <div class="preset-item__header">
                        <h3 class="preset-item__name">{{ preset.name }}</h3>
                        <md-button class="md-icon-button md-dense" @click="handleRemovePreset(preset.id, $event)">
                            <md-icon>delete</md-icon>
                        </md-button>
                    </div>

                    <div class="preset-item__filters">
                        <div v-for="(value, key) in preset.filters" :key="key" class="preset-item__filter">
                            <span class="filter-label">{{ formatFilterLabel(key) }}:</span>
                            <span class="filter-value">
                                <template v-if="Array.isArray(value)">
                                    {{ value.join(', ') }}
                                </template>
                                <template v-else>
                                    {{ value }}
                                </template>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="presets-list__actions">
                <md-button
                    @click="handleApplyPreset"
                    class="md-raised md-primary"
                    :disabled="!selectedPresetId || isLoading"
                >
                    {{ isLoading ? 'Applying...' : 'Apply filters' }}
                </md-button>
                <md-button @click="closeModal()" class="md-gray md-outline">Cancel</md-button>
            </div>
        </md-content>
    </div>
</template>

<style lang="scss" scoped>
.presets-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f5f5f5;
    width: 100%;
    max-width: 100%;

    @media (min-width: 768px) {
        width: 500px;
        max-width: 500px;
        margin: 0 auto;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        padding: 1rem 0.5rem 0 1rem;
        background-color: #ffffff;
    }

    &__content {
        flex-grow: 1;
        padding: 0 2rem;
        overflow-y: auto;
    }

    &__title {
        font-size: 22px;
        font-weight: 500;
        margin: 0;
        // color: #431ed9;
    }

    &__title-container {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }

    &__icon {
        // color: #431ed9;
        font-size: 24px;
        margin-right: 8px;
    }

    &__empty {
        text-align: center;
        padding: 2rem;
        color: rgba(0, 0, 0, 0.54);

        .empty-icon {
            font-size: 48px;
            margin-bottom: 1rem;
        }
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
        margin-bottom: 1rem;
        gap: 16px;
    }
}

.preset-item {
    background: white;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
    border: 1px solid #e1e5e8;

    &:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &--selected {
        border-color: #4a24e1;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;
    }

    &__name {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
    }

    &__filters {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    &__filter {
        background: #f5f5f5;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 14px;

        .filter-label {
            color: rgba(0, 0, 0, 0.6);
            margin-right: 4px;
        }

        .filter-value {
            font-weight: 500;
        }
    }
}
</style>
