<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'SaveFiltersPreset',
    props: {
        activeFilters: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            presetName: '',
            nameError: '',
        };
    },
    computed: {
        ...mapGetters('activeFilters', ['presets']),
        isValid() {
            return this.presetName.trim().length > 0;
        },
        currentFiltersPreview() {
            return Object.entries(this.activeFilters).map(([key, value]) => ({
                label: this.formatFilterLabel(key),
                value: value,
            }));
        },
    },
    methods: {
        ...mapActions('activeFilters', ['savePreset']),
        ...mapActions('modalStore', ['closeModal']),

        formatFilterLabel(key) {
            const labels = {
                agent: 'Agent',
                sentiment: 'Sentiment',
                tags: 'Tags',
            };
            return labels[key] || key;
        },

        validateName() {
            this.nameError = '';
            if (!this.presetName.trim()) {
                this.nameError = 'Preset name is required';
                return false;
            }
            if (this.presets.some((preset) => preset.name === this.presetName.trim())) {
                this.nameError = 'A preset with this name already exists';
                return false;
            }
            return true;
        },

        async handleSave() {
            if (!this.validateName()) return;

            this.isLoading = true;
            try {
                await this.savePreset({
                    name: this.presetName.trim(),
                    filters: this.activeFilters,
                });
                this.$toasted.success('Filter preset saved successfully', {
                    position: 'bottom-center',
                    duration: 3000,
                });
                this.closeModal();
            } catch (error) {
                console.error('Error saving preset:', error);
                this.$toasted.error('Failed to save preset. Please try again.', {
                    position: 'bottom-center',
                    duration: 3000,
                });
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<template>
    <div class="save-preset">
        <div class="save-preset__header">
            <md-button @click="closeModal()" class="md-icon-button">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <md-content class="save-preset__content">
            <div class="save-preset__title-container">
                <span class="material-symbols-outlined save-preset__icon">bookmark_add</span>
                <p class="save-preset__title md-title">Save Filter Preset</p>
            </div>

            <div class="preset-form">
                <md-field :class="{ 'md-invalid': nameError }">
                    <label>Preset Name</label>
                    <md-input v-model="presetName" required @input="nameError = ''"></md-input>
                    <span class="md-error">{{ nameError }}</span>
                </md-field>

                <div class="current-filters">
                    <p class="md-subheading bold">Current Filters:</p>
                    <div class="filters-preview">
                        <div v-for="filter in currentFiltersPreview" :key="filter.label" class="filter-item">
                            <span class="filter-label">{{ filter.label }}:</span>
                            <strong class="filter-value">
                                <template v-if="Array.isArray(filter.value)">
                                    {{ filter.value.join(', ') }}
                                </template>
                                <template v-else>
                                    {{ filter.value }}
                                </template>
                            </strong>
                        </div>
                    </div>
                </div>
            </div>

            <div class="save-preset__actions">
                <md-button @click="handleSave" class="md-raised md-primary" :disabled="isLoading || !isValid">
                    {{ isLoading ? 'Saving...' : 'Save Preset' }}
                </md-button>
                <md-button @click="closeModal()" class="md-gray md-outline">Cancel</md-button>
            </div>
        </md-content>
    </div>
</template>

<style lang="scss" scoped>
.save-preset {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f5f5f5;
    width: 100%;
    max-width: 100%;

    @media (min-width: 768px) {
        width: 500px;
        max-width: 500px;
        margin: 0 auto;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        padding: 1rem 0.5rem 0 1rem;
        background-color: #ffffff;
    }

    &__title {
        font-size: 22px;
        font-weight: 500;
        margin: 0;
        // color: #1976d2;
    }

    &__content {
        flex-grow: 1;
        padding: 0 2rem;
        overflow-y: auto;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
        margin-bottom: 1rem;
        gap: 16px;
    }

    &__title-container {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }

    &__icon {
        // color: #1976d2;
        font-size: 24px;
        margin-right: 8px;
    }
}

.preset-form {
    margin-top: 1rem;
}

.current-filters {
    margin-top: 2rem;
}

.filters-preview {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 1rem;
    border: 1px solid #e1e5e8;
    // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.filter-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid #eee;

    &:last-child {
        border-bottom: none;
    }
}

.filter-label {
    color: rgba(0, 0, 0, 0.6);
}

.filter-value {
    color: rgba(0, 0, 0, 0.87);
}

.bold {
    font-weight: bold;
}
</style>
