<template>
    <div class="custom-main-panel">
        <div class="custom-content-panel">
            <vue-advanced-chat
                class="custom-chat-panel"
                :height="screenHeight"
                :current-user-id="currentUserId"
                :current-user-name="currentUserName"
                :current-user-email="currentUserEmail"
                :current-user-phone="currentUserPhone"
                :current-user-satisfaction="currentUserSatisfaction"
                :rooms="rooms"
                :rooms-loaded="roomsLoaded"
                :room-actions="roomActions"
                :loading-rooms="loadingRooms"
                :messages="JSON.stringify(messages)"
                :messages-loaded="messagesLoaded"
                :message-actions="this.$store.getters['user/isDevMode'] ? messageActions : []"
                :stateAI="stateAI"
                :showAIToggle="true"
                :enable-info-customer="enableInfoCustomer"
                :show-customer-details="showCustomerDetails"
                :show-customer-header="showCustomerHeader"
                :showDetailsOrder="false"
                :orders="customerOrders"
                :loadingOrders="loadingOrders"
                :notes="notes"
                :show-audio="false"
                :scroll-distance="60"
                :show-add-room="true"
                @fetch-more-rooms="fetchMoreRooms"
                @fetch-messages="fetchMessages"
                @update-room-id="updateRoomId"
                @update-send-new-menssage="updateSendNewMenssage"
                @send-message="sendMessage($event)"
                @fetch-state-AI="fetchStateAI($event)"
                @search-room="fetchSearchRooms($event)"
                @customer-info="customerInfo"
                @get-notes-order="getNotesOrder"
                @filter-activated="(agent, sentiment, tags) => activateFilter(agent, sentiment, tags)"
                @fineTuning="(action, message) => createFineTuning(action, message)"
                @close-mobile-view="showCustomerDetails = false"
                @copy-room-link="(room) => onCopyRoomLink(room)"
                @message-action-handler="handleMessageActions"
                @add-room="showAddRoomModal"
                @room-action-handler="handleRoomActions"
            />
        </div>
        <generateScenarioWizard
            :showDialog="fineTuningOptions.showDialog"
            :context="fineTuningOptions.context"
            :correctAnswer="fineTuningOptions.correctAnswer"
            :wrongAnswer="fineTuningOptions.wrongAnswer"
            :message="fineTuningOptions.message"
            @ScenarioCreated="(newMessage) => handleScenarioCreated(newMessage)"
            @DialogClosed="handleDialogClose"
        />
    </div>
</template>
<script>
import { register } from '@/lib/index';
import { transformDate, transformDateLong, formatDateTime, capitalizeString } from '@/utils/common';
import { v4 as uuidv4 } from 'uuid';
import { mapActions } from 'vuex';
import generateScenarioWizard from '@/components/Training/FineTuning/generateScenarioWizard.vue';
import conversationsService from '../../../services/conversations/conversationsService.js';
import storageService from '../../../services/google/storageService.js';
import {
    ConversationsWebSocketMixin,
    MessageActionsMixin,
    SearchRoomMixin,
    CreateConversationMixin,
} from '@/pages/Dashboard/Conversations/Mixins';
import userService from '@/services/account/userService.js';
import customerApiRepository from '@/services/customer/CustomerApiRepository.js';
import EditTags from '@/components/Chat/EditTags/EditTags.vue';

register();

export default {
    name: 'ChatPage',
    components: {
        generateScenarioWizard,
    },
    mixins: [ConversationsWebSocketMixin, MessageActionsMixin, SearchRoomMixin, CreateConversationMixin],
    data() {
        return {
            currentUserId: '1234', // usuario propietario del chat
            currentIdCustomer: '', // usuario propietario del chat
            currentUserName: '', // usuario en la conversacion
            currentUserEmail: '',
            currentUserPhone: '',
            currentUserSatisfaction: '',
            rooms: [], // Los que se envia al componente
            customRooms: [], // Array para tener el control
            roomsLoaded: false,
            loadingRooms: false,
            loadingOrders: false,
            messages: [],
            messagesLoaded: false,
            currentMessagesPage: 0,
            totalMessages: 0,
            messagePageSize: 20,

            currentRoomPage: 0,
            roomsPerPage: 20,
            totalRooms: 0,
            currentRoomId: '',
            currentChannelId: '',
            currentChannelType: '',
            startRooms: null,
            endRooms: null,
            allUsers: [],
            loadingLastMessageByRoom: 0,
            // roomsLoadedCount: 0,
            selectedRoom: null,
            messagesPerPage: 20,
            roomMessage: '',
            lastLoadedMessage: null,
            previousLastLoadedMessage: null,
            roomsListeners: [],
            listeners: [],
            menuActions: [{ name: 'copyLink', title: 'Copy Link', icon: 'paperclip' }],
            sendAddMessage: '',
            lastDateRooms: '',

            // Socket
            stompClient: null,
            isConnected: false,
            isReconnect: false,
            messageOutputs: null,
            subsConversations: null,
            subsMessages: null,
            stateAI: true,

            // Habilitar el ver la info del customer
            enableInfoCustomer: true, // Habilita el click para mostrar el componente
            showCustomerDetails: true, // Muestra el componente
            showCustomerHeader: true, // Muestra el componente de opciones

            //Ordenes del Cliente
            customerOrders: {},

            // Notas del Cliente
            notes: null,

            activeAgentFilter: 'ALL',
            activeSentimentFilter: 'ALL',
            activeTagsFilter: [],

            fineTuningOptions: {
                context: '',
                correctAnswer: '',
                wrongAnswer: '',
                showDialog: false,
                message: {},
            },

            roomActions: [
                { name: 'MarkAsRead', title: 'Mark as Read' },
                { name: 'MarkAsUnread', title: 'Mark as Unread' },
                { name: 'UpdateTags', title: 'Edit Tags' },
            ],
        };
    },
    watch: {
        // activeAgentFilter(newFilter, oldFilter) {
        //     if (newFilter === oldFilter) {
        //         return;
        //     }
        //     // Create a Date object for the current date and time
        //     this.currentRoomPage = 0;
        //     var now = new Date();
        //     this.lastDateRooms = now.getTime();
        //     this.fetchRooms();
        // },
        // activeSentimentFilter(newFilter, oldFilter) {
        //     if (newFilter === oldFilter) {
        //         return;
        //     }
        //     // Create a Date object for the current date and time
        //     this.currentRoomPage = 0;
        //     var now = new Date();
        //     this.lastDateRooms = now.getTime();
        //     this.fetchRooms();
        // },
    },
    computed: {
        loadedRooms() {
            return this.rooms;
        },
        screenHeight() {
            return this.isWalkonLivosAccount ? 'calc(100vh - 40px)' : '100vh';
            // return (window.innerHeight - 70) + "px";
            // return this.isDevice ? window.innerHeight + "px" : "calc(100vh - 80px)";
        },
        isMobile() {
            return window.innerWidth < Number(900);
        },
        isCopilotEnabled() {
            return this.$store.getters['user/isCopilotEnabled'];
        },
        isWalkonLivosAccount() {
            if (!this.$store.state.user.user || !this.$store.state.user.user.email) {
                return false;
            }

            const email = this.$store.state.user.user.email;
            const domain = email.substring(email.lastIndexOf('@') + 1);
            return domain === 'walkonlivos.com';
        },
        activeFiltersState() {
            return this.$store.getters['activeFilters/activeFilters'];
        },
    },
    methods: {
        ...mapActions('user', [
            'apiCall', // Action from the 'user' module
        ]),
        ...mapActions('modalStore', ['openModal']),

        handleRoomActions(event) {
            const { roomId, action } = event;

            switch (action.name) {
                case 'MarkAsRead':
                    console.log('MarkAsRead');
                    this.restartUnreadCount(roomId);
                    break;
                case 'MarkAsUnread':
                    console.log('MarkAsUnread');
                    this.markMessageAsUnread(roomId);
                    break;
                case 'UpdateTags':
                    this.handleUpdateConversationTags(roomId);
                    break;
                default:
                    break;
            }
        },

        handleUpdateConversationTags(roomId) {
            const room = this.rooms.find((room) => room.roomId === roomId);
            this.openModal({
                component: EditTags,
                props: {
                    tags: room.tags ? [...room.tags] : [],
                },
                onEvent: (eventName, eventPayload) => {
                    if (eventName === 'update-tags') {
                        this.fetchUpdateConversationTags(roomId, eventPayload);
                    }
                },
            });
        },

        async fetchUpdateConversationTags(roomId, tags) {
            try {
                await conversationsService.updateConversationTags(roomId, tags);
                const room = this.rooms.find((room) => room.roomId === roomId);
                room.tags = [...tags];
            } catch (error) {
                console.error('Error updating conversation tags:', error);
            }
        },

        async markMessageAsUnread(roomId) {
            const roomIndex = this.rooms.findIndex((room) => room.roomId === roomId);

            if (roomIndex !== -1 && this.rooms[roomIndex].unreadCount === 0) {
                try {
                    await conversationsService.setConversationAsUnread(roomId);
                } catch (error) {
                    console.error('Error marking conversation as unread:', error);
                }
                this.rooms[roomIndex].unreadCount++;
            }
        },

        onCopyRoomLink(room) {
            const roomRoute = this.$router.resolve({ name: 'Customer Support', query: { search: room.roomId } });
            navigator.clipboard.writeText(`${window.location.origin}${roomRoute.href}`);
            this.$toasted.success('Link Copied', {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        createFineTuning(action, currentMessage) {
            const previousMessage = this.findPreviousMessage(currentMessage);

            if (previousMessage.summary === null) {
                this.fineTuningOptions.context = previousMessage.content;
            } else {
                this.fineTuningOptions.context = previousMessage.summary;
            }

            switch (action) {
                case 'like':
                    this.fineTuningOptions.correctAnswer = currentMessage.content;
                    break;
                case 'dislike':
                    this.fineTuningOptions.wrongAnswer = currentMessage.content;
                    break;
                default:
                    break;
            }

            this.fineTuningOptions.message = currentMessage;

            this.fineTuningOptions.showDialog = true;
        },

        findPreviousMessage(currentMessage) {
            const currentMessageIndex = this.messages.findIndex(
                (message) => message.indexId === currentMessage.indexId,
            );

            let previousMessage = null; // Take the previuos message for context

            // Start from the message before the current message, if it exists
            for (let i = currentMessageIndex - 1; i >= 0; i--) {
                if (this.messages[i].username !== 'Lixsa') {
                    previousMessage = this.messages[i];
                    break; // Break once the previous valid message is found
                }
            }

            return previousMessage;
        },

        handleScenarioCreated(newMessage) {
            this.handleDialogClose();
            this.updateMessageAfterScenarioCreated(newMessage);
        },

        handleDialogClose() {
            this.fineTuningOptions.showDialog = false;
            this.fineTuningOptions.context = '';
            this.fineTuningOptions.correctAnswer = '';
            this.fineTuningOptions.wrongAnswer = '';
            this.fineTuningOptions.message = {};
        },

        updateMessageAfterScenarioCreated(newMessage) {
            const currentMessageIndex = this.messages.findIndex((message) => message.indexId === newMessage.indexId);

            this.messages[currentMessageIndex].valoration = newMessage.valoration;
            this.messages[currentMessageIndex].scenario_id = newMessage.scenario_id;
        },

        performFilterFetch() {
            this.currentRoomPage = 0;
            var now = new Date();
            this.lastDateRooms = now.getTime();
            this.fetchRooms();
        },

        activateFilter(agent, sentiment, tags) {
            this.activeAgentFilter = agent;
            this.activeSentimentFilter = sentiment;
            this.activeTagsFilter = [...tags];

            this.performFilterFetch();
        },

        resetRooms() {
            this.loadingRooms = true;
            this.loadingLastMessageByRoom = 0;
            // this.roomsLoadedCount = 0;
            this.rooms = [];
            this.customRooms = [];
            this.roomsLoaded = false;
            this.startRooms = null;
            this.endRooms = null;
            this.roomsListeners.forEach((listener) => listener());
            this.roomsListeners = [];
        },
        resetMessages() {
            this.messages = [];
            this.messagesLoaded = false;
            this.lastLoadedMessage = null;
            this.previousLastLoadedMessage = null;
            this.listeners.forEach((listener) => listener());
            this.listeners = [];
        },
        fetchRooms() {
            this.resetRooms();
            this.resetMessages();
            this.fetchMoreRooms();
        },
        async fetchMoreRooms() {
            if (!this.isOnSearch) {
                if (this.currentRoomPage === 0) {
                    this.resetRooms();
                    this.resetMessages();
                }
                this.fetchConversations()
                    .then((response) => {
                        this.currentRoomPage++;
                        this.processData(response);
                        if (this.rooms.length === this.totalRooms) {
                            this.roomsLoaded = true;
                        }
                    })
                    .catch((error) => {
                        console.error('Error', error);
                    });
            } else {
                await this.performSearch(this.lastSearchTerm);
            }
        },
        async sendMessage(info) {
            let myUuid = null;
            let message = null;
            let file = null;
            let typeMensaje = 'TEXT';
            let content = '';

            let infoContent = info.content;
            if (this.isCopilotEnabled) {
                infoContent = await this.fixSpellAndGrammar(info);
            }

            let [customerFirstName, customerLastName] = this.currentUserName.split(' ');
            customerLastName = customerLastName || ''; // In case there's no last name

            if (info.files !== null) {
                // Envia Imagenes, videos, audios y documentos
                info.files.forEach((element, index) => {
                    myUuid = uuidv4();

                    const fileType = info.files[index].type;
                    const typeMap = {
                        'image/': 'IMAGE',
                        'video/': 'VIDEO',
                        'audio/': 'AUDIO',
                        'application/': 'DOCUMENT',
                    };
                    typeMensaje =
                        Object.entries(typeMap).find(([key, _]) => fileType.startsWith(key) || fileType === key)?.[1] ||
                        'UNKNOWN';

                    file = element;
                    content = null;

                    message = {
                        uuid: myUuid,
                        conversation: this.currentRoomId,
                        account: this.currentUserId,
                        content: content,
                        caption: infoContent,
                        channel_id: this.currentChannelId,
                        channel_type: this.currentChannelType,
                        channel_message_type: typeMensaje,
                        media_content: file,
                        role_type: 'AI',
                        timestamp: new Date(),
                        customer_first_name: customerFirstName,
                        customer_lastname: customerLastName,
                    };
                    this.fetchSendMessageFiles(message);
                });
            } else {
                myUuid = uuidv4();
                // Envia texto
                content = infoContent;
                typeMensaje = 'TEXT';
                file = null;

                message = {
                    uuid: myUuid,
                    conversation: this.currentRoomId,
                    account: this.currentUserId,
                    content: content,
                    caption: '',
                    channel_id: this.currentChannelId,
                    channel_type: this.currentChannelType,
                    channel_message_type: typeMensaje,
                    media_content: file,
                    role_type: 'AI',
                    timestamp: new Date(),
                    customer_first_name: customerFirstName,
                    customer_lastname: customerLastName,
                };
                this.fetchSendMessage(message);
            }
        },
        async fixSpellAndGrammar(message) {
            try {
                const previousMessage = this.lastClientMessage();
                const previousContent = previousMessage ? previousMessage.content : null;
                const response = await conversationsService.getCopilotCorrection(
                    this.currentRoomId,
                    message.content,
                    previousContent,
                );
                return response.data;
            } catch (error) {
                console.error('Error fixing spelling and grammar: ', error);
            }
        },
        lastClientMessage() {
            let lastMessage = null;

            for (let i = this.messages.length - 1; i >= 0; i--) {
                if (this.messages[i].username !== 'Lixsa') {
                    lastMessage = this.messages[i];
                    break;
                }
            }

            return lastMessage;
        },
        async fetchSendMessage(message) {
            try {
                await conversationsService.sendMessage(message);
            } catch (error) {
                console.error('Error sending message: ', error);
            }
        },
        async fetchSendMessageFiles(info) {
            try {
                const fileContent = this.getFileContent(info.media_content);
                const myChannelID = this.encodeChannelId(info.channel_id);
                const urlStorage = await this.getStorageUrl(myChannelID, info.media_content.type);

                if (urlStorage) {
                    const responseGoogle = await this.uploadFileToGoogleCloud(
                        urlStorage,
                        fileContent,
                        info.media_content.type,
                    );
                    if (responseGoogle.status === 200) {
                        await this.sendFileMessage(info, urlStorage);
                    }
                }
            } catch (error) {
                console.error('Error in fetchSendMessageFiles:', error);
            }
        },

        getFileContent(mediaContent) {
            const isMediaFile = /^(image|video|audio|application)\//.test(mediaContent.type);
            return isMediaFile ? mediaContent.selectedFile : mediaContent;
        },

        encodeChannelId(channelId) {
            return channelId.replace('+', '%2B');
        },

        async getStorageUrl(channelId, fileType) {
            try {
                return await conversationsService.getStorageURL(channelId, fileType, uuidv4());
            } catch (error) {
                console.error('Error getting storage URL:', error);
                return null;
            }
        },

        async uploadFileToGoogleCloud(url, content, type) {
            try {
                return await storageService.uploadFileToGoogleCloud(url, content, type);
            } catch (error) {
                console.error('Error uploading file:', error);
                return { status: null };
            }
        },

        async sendFileMessage(info, urlStorage) {
            try {
                let message = { ...info, content: urlStorage.split('?')[0] };
                await conversationsService.sendMessage(message);
            } catch (error) {
                console.error('Error sending message:', error);
            }
        },

        // Fetch de conversaciones por Id de cuenta
        // y/o desde una fecha en particular y paginadas
        // siempre y cuando no exista valor en el input de búsqueda
        async fetchConversations() {
            try {
                const page = this.currentRoomPage;
                const size = this.roomsPerPage;
                const timestamp = this.lastDateRooms && !this.isOnSearch ? this.lastDateRooms : '';
                let filters = {};

                if (this.activeAgentFilter !== 'ALL') {
                    filters.agent = [this.activeAgentFilter];
                }

                if (this.activeSentimentFilter !== 'ALL') {
                    filters.sentiment = [this.activeSentimentFilter];
                }

                if (this.activeTagsFilter.length > 0) {
                    filters.tag = [...this.activeTagsFilter];
                }

                const response = await conversationsService.fetchConversations(
                    page,
                    size,
                    null,
                    Object.keys(filters).length === 0 ? null : filters,
                );
                this.totalRooms = response.data.totalElements;
                return response.data;
            } catch (error) {
                console.error('Error obtaining conversations:', error);
            }
        },
        // Ordena la data de la manera que la pide el componente chat
        processData(data) {
            try {
                if (data) {
                    this.loadingRooms = true;

                    let firstConversationId = '';

                    const info = data.content.map((item) => {
                        this.lastDateRooms = item.date_updated;
                        firstConversationId = firstConversationId === '' ? item.uuid : firstConversationId;

                        this.currentUserId = item.account;
                        if (this.loadedRooms.length === 0) {
                            // Update client information only the first time the rooms list loads.
                            this.roomId = this.roomId === '' ? item.uuid : this.roomId;
                            this.currentUserName =
                                this.currentUserName === ''
                                    ? item.customer_lastname === null
                                        ? item.customer_first_name
                                        : item.customer_lastname + ' ' + item.customer_first_name
                                    : this.currentUserName;
                            this.currentUserEmail = item.customer_email ? item.customer_email : null;
                            this.currentUserPhone = item.customer_phone ? item.customer_phone : item.channel_id;

                            this.currentUserSatisfaction =
                                item.satisfaction !== 'UNDEFINED' ? item.satisfaction : 'NOT ANALYZED';
                        }

                        let avatarChannel =
                            'https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2FfotoPerfilUsuarioNuevo.png?alt=media&token=90c43fbd-87fe-411f-b0cd-06813fd73be0';
                        let dateConversation = transformDate(item.date_updated);
                        /**
                         * TODO: mejorar con swith o abjeto si hay mas tipos de canales
                         */
                        // if (item.channel_type === "WHATSAPP") {
                        //   avatarChannel =
                        //     "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/240px-WhatsApp.svg.png";
                        // } else {
                        //   avatarChannel =
                        //     "https://66.media.tumblr.com/avatar_c6a8eae4303e_512.pnj";
                        // }

                        // Eliminar el null de last_Mesagge
                        let lastMessageUuid = '';
                        let lastMessageContent = '';

                        if (item.last_message.uuid === null) {
                            const myUuid = uuidv4();
                            lastMessageUuid = myUuid;
                            lastMessageContent = '.';
                        }

                        lastMessageContent = this.getLastMessageContentByType(item);

                        const firstName = item.customer_first_name ? item.customer_first_name : 'Guest';
                        const lastName = item.customer_lastname ? item.customer_lastname : '';

                        return {
                            roomId: item.uuid,
                            currentUserId: item.account,
                            roomName: firstName + ' ' + lastName,
                            avatar: avatarChannel,
                            userEmail: item.customer_email ? item.customer_email : null,
                            userPhone: item.customer_phone ? item.customer_phone : item.channel_id,
                            userSatisfaction: item.satisfaction !== 'UNDEFINED' ? item.satisfaction : 'NOT ANALYZED',
                            /**
                             * TODO: unreadCount: determina si no hay mensajes leidos, index: buscar que es
                             */
                            unreadCount: item.read ? 0 : 1,
                            channelId: item.channel_id,
                            channel_type: item.channel_type,
                            index: 1,
                            tags: item.tags ? [...item.tags] : [],
                            lastMessage: {
                                _id: item.last_message.uuid !== null ? item.last_message.uuid : lastMessageUuid,
                                content: lastMessageContent,
                                senderId: item.customer_id,
                                username:
                                    item.role_type === 'HUMAN'
                                        ? item.customer_lastname + ' ' + item.customer_first_name
                                        : 'Lixsa',
                                timestamp: dateConversation,
                                date: transformDateLong(item.date_updated),
                                saved: true,
                                distributed: false,
                                seen: true,
                                new: true,
                            },
                            users: [
                                {
                                    _id: '4321',
                                    username: 'Lixsa',
                                    avatar: 'https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2FfotoPerfilLixsaNuevo.png?alt=media&token=10a50425-4b7f-4118-917a-ba5ed88f4f29',
                                    status: {
                                        state: 'offline',
                                        lastChanged: item.date_updated
                                            ? formatDateTime(item.date_updated)
                                            : 'today, 14:30',
                                    },
                                },
                                {
                                    _id: item.customer_id, //"4321",
                                    username: item.customer_lastname + ' ' + item.customer_first_name,
                                    avatar: avatarChannel,
                                    status: {
                                        state: 'offline',
                                        lastChanged: item.date_updated
                                            ? formatDateTime(item.date_updated)
                                            : 'today, 14:30',
                                    },
                                },
                            ],
                            typingUsers: [4321], // quien esta escribiendo o 4321 item.customer_id
                            is_aion: item.is_aion,
                            room_ai_type: item.ai_type,
                            communication_channel: item.channel_type,
                            note: item.note,
                        };
                    });

                    // Reardonar las conversaciones
                    this.reorderRooms(info);

                    this.rooms = this.customRooms;
                    this.loadingRooms = false; // Oculto el loader de las conversaciones
                }
            } catch (error) {
                console.error('Error procesing data: ', error);
            }
        },
        getLastMessageContentByType(room) {
            let lastMessageConent;
            const message_type = room.last_message.channel_message_type
                ? room.last_message.channel_message_type.toUpperCase()
                : room.last_message.channel_message_type;
            switch (message_type) {
                case 'AUDIO':
                    lastMessageConent = '🎤 Audio';
                    storageService
                        .fetchAudioDetails(room.last_message.content)
                        .then((audioFileInfo) => {
                            const index = this.rooms.findIndex((element) => element.roomId === room.uuid);

                            if (index !== -1) {
                                this.rooms[index].lastMessage.content =
                                    `🎤 ${this.formatDuration(audioFileInfo.duration)}`;
                            }
                        })
                        .catch((error) => {
                            console.error('Error fetching audio details: ', error);
                        });
                    break;

                case 'IMAGE':
                    const imageCaption = room.last_message.caption ? room.last_message.caption : 'Photo';
                    lastMessageConent = `📷 ${imageCaption}`;
                    break;

                case 'DOCUMENT':
                    const documentCaption = room.last_message.caption ? room.last_message.caption : 'Document';
                    lastMessageConent = `📄 ${documentCaption}`;
                    break;

                case 'VIDEO':
                    const videoCaption = room.last_message.caption ? room.last_message.caption : 'Video';
                    lastMessageConent = `📹 ${videoCaption}`;
                    break;

                default:
                    lastMessageConent = room.last_message.content === null ? '.' : room.last_message.content;
                    break;
            }

            return lastMessageConent;
        },
        formatDuration(seconds) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = Math.floor(seconds % 60);
            const paddedSeconds = remainingSeconds.toString().padStart(2, '0');
            return `${minutes}:${paddedSeconds}`;
        },

        // Ordena las conversaciones
        reorderRooms(info) {
            if (!info) {
                console.error('info NULL');
                return;
            }

            if (this.customRooms.length === 0) {
                this.customRooms = info;
                return;
            }

            if (!info.name) {
                this.customRooms = this.customRooms.concat(info);
                return;
            }

            this.handleRoomUpdate(info);
        },

        handleRoomUpdate(info) {
            const conversationId = info.conversation;
            if (!conversationId) return;

            const messageContent = this.parseMessageContent(info.data);
            if (!messageContent) return;

            const existingRoom = this.findExistingRoom(conversationId);
            const isMessageDuplicate = this.isMessageDuplicate(conversationId, messageContent.last_message.uuid);

            if (!isMessageDuplicate) {
                existingRoom
                    ? this.updateExistingRoom(existingRoom, messageContent)
                    : this.createNewRoom(info, messageContent);
            }
        },

        parseMessageContent(data) {
            try {
                return JSON.parse(data);
            } catch (error) {
                console.error('Error parsing message content:', error);
                return null;
            }
        },

        findExistingRoom(conversationId) {
            return this.customRooms.find((room) => room.roomId === conversationId);
        },

        isMessageDuplicate(conversationId, messageId) {
            return this.customRooms.some(
                (room) => room.roomId === conversationId && room.lastMessage._id === messageId,
            );
        },

        updateExistingRoom(room, content) {
            this.updateLastMessage(room, content);
            this.setRoomUsersOnline(room);
            this.updateCustomerInfo(room, content);
            this.moveRoomToTop(room);
        },

        updateLastMessage(room, content) {
            room.lastMessage = {
                content: this.getLastMessageContentByType(content),
                timestamp: transformDate(content.last_message.date_created || content.date_created),
                date: transformDateLong(content.last_message.date_created || content.date_created),
                _id: content.last_message.uuid || content.uuid,
            };
            if (!content.read) room.unreadCount++;
        },

        setRoomUsersOnline(room) {
            room.users.forEach((user) => {
                user.status.state = 'online';
            });
        },

        updateCustomerInfo(room, content) {
            const { firstName, lastName } = this.formatCustomerName(content);
            room.roomName = `${lastName} ${firstName}`;
            room.userEmail = content.customer_email;
            room.userPhone = content.customer_phone;
        },

        moveRoomToTop(room) {
            const remainingRooms = this.customRooms.filter((item) => item.roomId !== room.roomId);
            this.customRooms = [room, ...remainingRooms];
            this.rooms = this.customRooms;
            this.loadingRooms = false;
        },

        createNewRoom(info, content) {
            const newRoom = this.customRooms.pop();
            if (!newRoom) return;

            const { firstName, lastName } = this.formatCustomerName(content);

            this.updateRoomBasicInfo(newRoom, info, content, firstName, lastName);
            this.updateRoomLastMessage(newRoom, content, firstName, lastName);
            this.updateRoomUsers(newRoom, info, content, firstName, lastName);

            this.customRooms.unshift(newRoom);
        },

        updateRoomBasicInfo(room, info, content, firstName, lastName) {
            room.roomId = info.conversation;
            room.unreadCount++;
            room.roomName = `${lastName} ${firstName}`;
            room.userEmail = content.customer_email;
            room.userPhone = content.customer_phone;
            room.channelId = content.channel_id;
            room.is_aion = content.is_aion;
            room.room_ai_type = content.ai_type;
            room.communication_channel = content.channel_type;
            room.userSatisfaction = content.satisfaction !== 'UNDEFINED' ? content.satisfaction : 'NOT ANALYZED';
            room.tags = content.tags ? [...content.tags] : [];
        },

        updateRoomLastMessage(room, content, firstName, lastName) {
            room.lastMessage = {
                content: this.getLastMessageContentByType(content),
                senderId: content.customer_id,
                timestamp: transformDate(content.last_message.date_created),
                date: transformDateLong(content.last_message.date_created),
                username: this.formatUsername(content),
                _id: content.last_message.uuid,
            };
        },

        updateRoomUsers(room, info, content, firstName, lastName) {
            room.users.forEach((user) => {
                user.status.state = 'online';
                if (user.username !== 'Lixsa') {
                    user.username = this.formatUsername(content);
                    user._id = info.customer_id;
                }
            });
        },

        formatCustomerName(content) {
            return {
                firstName: content.customer_first_name || 'Guest',
                lastName: content.customer_lastname || '',
            };
        },

        formatUsername(content) {
            return content.customer_lastname === null
                ? content.customer_first_name
                : `${content.customer_lastname} ${content.customer_first_name}`;
        },

        async fetchMessages() {
            if (this.roomId === '') {
                console.error('Invalid conversation ID');
                return;
            }

            if (!this.messages.length) {
                await this.loadMessagesFirstTime();
            } else {
                await this.loadMoreMessages();
            }

            this.messagesLoaded = this.messages.length >= this.totalMessages;
        },
        async loadMessagesFirstTime() {
            const conversationId = this.roomId;
            this.messages = [];
            this.currentMessagesPage = 0;
            this.messagesLoaded = false;

            try {
                const response = await conversationsService.fetchMessages(
                    conversationId,
                    this.currentMessagesPage,
                    this.messagePageSize,
                );

                const newMessages = response.data.content;
                this.totalMessages = response.data.totalElements;

                this.checkoutMessages(newMessages, conversationId);
            } catch (error) {
                console.error('Error fetching the messages', error);
            }
        },
        async loadMoreMessages() {
            const conversationId = this.roomId;

            try {
                this.currentMessagesPage++;

                const response = await conversationsService.fetchMessages(
                    conversationId,
                    this.currentMessagesPage,
                    this.messagePageSize,
                );

                const newMessages = response.data.content;
                this.totalMessages = response.data.totalElements;

                this.addOldMessages(newMessages.reverse());
            } catch (error) {
                console.error('Error fetching the messages', error);
            }
        },
        addOldMessages(newMessages) {
            newMessages.map((message) => {
                this.messages = [this.formatMessage(message), ...this.messages];
                this.updateAgentUsername(message);
            });
        },
        checkoutMessages(data, id) {
            this.currentRoomId = id;
            // Buscar en el las conversacionas la concurrente
            const foundObject = this.rooms.find((obj) => obj.roomId === id);
            if (foundObject) {
                this.currentChannelId = foundObject.channelId;
                this.currentChannelType = foundObject.channel_type;
                this.stateAI = foundObject.is_aion;
            } else {
                this.currentChannelId = '';
                this.currentChannelType = '';
            }
            this.subscribeToRoomMessages(id);
            this.processMessages(data);
        },
        handleRoomsListEvent(messageOutput) {
            if (messageOutput.conversation === this.$store.state.user.user.uuid) return;

            const messageData = JSON.parse(messageOutput.data);

            console.log('handleRoomsListEvent', messageOutput.name, messageData);

            switch (messageOutput.name) {
                case 'ConversationUpdated':
                case 'ConversationCreated':
                    this.handleConversationUpdateOrCreate(messageOutput);
                    break;
                case 'ConversationAIMuteChanged':
                    this.handleAIMuteChange(messageOutput);
                    break;
                case 'ConversationAgentChanged':
                    this.handleAgentChange(messageOutput);
                    break;
                case 'ConversationSentimentChanged':
                    this.handleSentimentChange(messageOutput);
                    break;
                default:
                    console.warn('Unrecognized message type:', messageOutput.name);
            }
        },
        handleConversationUpdateOrCreate(messageParsed) {
            const messageData = JSON.parse(messageParsed.data);

            const isAllFilters = this.activeAgentFilter === 'ALL' && this.activeSentimentFilter === 'ALL';
            const matchesFilters =
                messageData.ai_type === this.activeAgentFilter &&
                messageData.satisfaction === this.activeSentimentFilter;

            if (isAllFilters || matchesFilters) {
                // this.updateCurrentRoomUser(messageParsed);
                this.reorderRooms(messageParsed);
            }
        },

        updateCurrentRoomUser(messageParsed) {
            if (this.currentRoomId === messageParsed.conversation) {
                this.currentIdCustomer = messageParsed.customer_id;

                const firstName = messageParsed.customer_first_name ? messageParsed.customer_first_name : 'Guest';
                const lastName = messageParsed.customer_lastname ? messageParsed.customer_lastname : '';
                this.currentUserName = `${lastName} ${firstName}`;

                this.currentUserEmail = messageParsed.customer_email;
                this.currentUserPhone = messageParsed.customer_phone;
                this.currentUserSatisfaction =
                    messageParsed.satisfaction !== 'UNDEFINED' ? messageParsed.satisfaction : 'NOT ANALYZED';
            }
        },

        handleAIMuteChange(messageParsed) {
            const messageData = JSON.parse(messageParsed.data);
            const index = this.rooms.findIndex((room) => room.roomId === messageData.uuid);
            this.rooms[index].is_aion = messageData.is_aion;
            this.rooms[index].room_ai_type = messageData.ai_type;
            if (this.currentRoomId === messageData.uuid) this.stateAI = messageData.is_aion;
        },

        handleAgentChange(messageParsed) {
            const messageData = JSON.parse(messageParsed.data);
            const index = this.rooms.findIndex((room) => room.roomId === messageData.uuid);
            this.rooms[index].room_ai_type = messageData.ai_type;
            if (messageData.ai_type === 'HUMAN_AGENT') {
                this.rooms[index].is_aion = messageData.is_aion;
                if (this.currentRoomId === messageData.uuid) this.stateAI = messageData.is_aion;
            }
        },

        handleSentimentChange(messageParsed) {
            const messageData = JSON.parse(messageParsed.data);
            const index = this.rooms.findIndex((room) => room.roomId === messageData.uuid);
            this.rooms[index].userSatisfaction =
                messageData.satisfaction !== 'UNDEFINED' ? messageData.satisfaction : 'NOT ANALYZED';
        },
        reorderMessage(info) {
            const contenido = JSON.parse(info.data);
            let data = null;
            if (info) {
                data = [
                    {
                        account: contenido.account,
                        content: contenido.content,
                        conversation: contenido.conversation,
                        customer_id: contenido.customer_id,
                        date_created: contenido.date_created,
                        date_deleted: null,
                        date_updated: null,
                        role_type: contenido.role_type,
                        summary: null,
                        uuid: contenido.uuid,
                        reply_uuid: contenido.reply_uuid,
                        channel_message_id: contenido.channel_message_id,
                        channel_message_type: contenido.channel_message_type
                            ? contenido.channel_message_type.toUpperCase()
                            : null,
                        caption: contenido.caption,
                        channel_message_status: contenido.channel_message_status
                            ? contenido.channel_message_status
                            : contenido.channel_status,
                        valoration: contenido.valoration,
                        scenario_id: contenido.scenario_id,
                        created_by: contenido.created_by,
                    },
                ];
            }
            return data;
        },
        // Ordena la data de la manera que la pide el componente chat
        processMessages(data) {
            if (data) {
                data.map((item) => {
                    this.addMessages(item);
                });
            }
        },
        getDataContentByMessageType(messageType, data) {
            let dataContent;
            switch (messageType.toUpperCase()) {
                case 'TEXT':
                case 'BUTTON':
                    // Prevent null variable.
                    dataContent = data.content ? data.content : '';
                    break;
                case 'IMAGE':
                case 'DOCUMENT':
                case 'VIDEO':
                    // There may be images without caption.
                    dataContent = data.caption ? data.caption : '';
                    break;
                default:
                    dataContent = '';
                    break;
            }

            return dataContent;
        },

        async updateAgentUsername(newMessage) {
            if (newMessage.role_type === 'HUMAN' || !newMessage.created_by) return;
            const foundedMessage = this.messages.find((message) => message._id === newMessage.uuid);
            foundedMessage.username = await this.obtainAgentInformation(newMessage);
        },

        async obtainAgentInformation(message) {
            try {
                const response = await userService.fetchUser(message.created_by);
                return response.data.username;
            } catch (error) {
                console.error('Error fetching user:', error);
                return 'Lixsa';
            }
        },

        formatMessage(message) {
            const messageType = message.channel_message_type ? message.channel_message_type.toUpperCase() : null;

            let dataFile = this.createDefaultDataFile(messageType, message.content);

            let dataContent = this.getDataContentByMessageType(messageType, message);

            dataContent = this.removeInitialTab(dataContent);

            const currentAgentAvatar = message.created_by
                ? null
                : 'https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2FfotoPerfilLixsaNuevo.png?alt=media&token=10a50425-4b7f-4118-917a-ba5ed88f4f29';

            let currentAgentUsername = 'Lixsa';

            return {
                _id: message.uuid,
                content: dataContent, // data.content,
                senderId: message.role_type === 'HUMAN' ? message.customer_id : this.currentUserId,
                avatar:
                    message.role_type === 'HUMAN'
                        ? 'https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2FfotoPerfilUsuarioNuevo.png?alt=media&token=90c43fbd-87fe-411f-b0cd-06813fd73be0'
                        : currentAgentAvatar,
                timestamp: message.date_created
                    ? transformDate(message.date_created)
                    : new Date().toString().substring(16, 21),
                date: message.date_created ? transformDateLong(message.date_created) : new Date().toDateString(),
                indexId: message.uuid,
                username: message.role_type === 'HUMAN' ? this.currentUserName : currentAgentUsername,
                system: false,
                saved: this.isSaved(message),
                distributed: this.isDistributed(message),
                seen: this.isSeen(message),
                deleted: this.isDeleted(message),
                failure: this.isFailure(message),
                failure_reason: message.channel_error,
                disableActions: false,
                disableReactions: true,
                files: dataFile,
                summary: message.summary,
                valoration: message.valoration,
                conversation: message.conversation,
                scenario_id: message.scenario_id,
                replyMessage: message.replied_message
                    ? {
                          content: this.removeInitialTab(
                              this.getDataContentByMessageType(
                                  message.replied_message.channel_message_type,
                                  message.replied_message,
                              ),
                          ),
                          senderId:
                              message.replied_message.role_type === 'HUMAN'
                                  ? message.replied_message.customer_id
                                  : this.currentUserId,
                          files: this.createDefaultDataFile(
                              message.replied_message.channel_message_type,
                              message.replied_message.content,
                          ),
                      }
                    : null,
            };
        },

        addMessages(data) {
            const formattedMessage = this.formatMessage(data);
            const messageExists = this.messages.some((message) => message._id === formattedMessage._id);
            if (!messageExists) {
                this.messages = [...this.messages, formattedMessage];
                this.updateAgentUsername(data);
            }
        },

        setMessageStatus(message) {
            const index = this.messages.findIndex((messageFound) => messageFound._id === message.uuid);
            if (index !== -1) {
                this.messages[index].saved = this.isSaved(message);
                this.messages[index].distributed = this.isDistributed(message);
                this.messages[index].seen = this.isSeen(message);
                this.messages[index].deleted = this.isDeleted(message);
                this.messages[index].failure = this.isFailure(message);
            }
        },

        createDefaultDataFile(messageType, content) {
            switch (messageType) {
                case 'TEXT':
                case 'BUTTON':
                    return '';
                case 'IMAGE':
                    return [
                        {
                            name: '',
                            size: 0,
                            type: 'png',
                            url: content,
                            preview: '',
                        },
                    ];
                case 'AUDIO':
                    return [
                        {
                            name: '',
                            size: 0,
                            type: 'mp3',
                            audio: true,
                            url: content,
                            preview: '',
                        },
                    ];
                case 'DOCUMENT':
                    return [
                        {
                            name: '',
                            size: 0,
                            type: 'pdf',
                            url: content,
                            preview: '',
                        },
                    ];
                case 'VIDEO':
                    return [
                        {
                            name: '',
                            size: 0,
                            type: 'mp4',
                            url: content,
                            preview: '',
                        },
                    ];
                default:
                    // return [
                    //     {
                    //         name: '',
                    //         size: 0,
                    //         type: '',
                    //         url: content,
                    //         preview: '',
                    //     },
                    // ];
                    return '';
            }
        },

        fetchFileDetailsByMessageType(messageType, message) {
            switch (messageType) {
                case 'TEXT':
                case 'BUTTON':
                    break;
                case 'IMAGE':
                    this.storageFetchImageDetails(message);
                    break;
                case 'AUDIO':
                    this.storageFetchAudioDetails(message);
                    break;
                case 'DOCUMENT':
                    this.storageFetchDocumentDetails(message);
                    break;
                default:
                    break;
            }
        },

        storageFetchImageDetails(data) {
            let dataFile;
            storageService
                .fetchImageDetails(data.content)
                .then((imageFileInfo) => {
                    dataFile = [
                        {
                            name: 'Image',
                            size: imageFileInfo.size,
                            type: imageFileInfo.type,
                            url: data.content,
                            preview: imageFileInfo.preview,
                        },
                    ];

                    const index = this.messages.findIndex((element) => element._id === data.uuid);

                    if (index !== -1) {
                        this.messages[index].files = [...dataFile];
                        this.messages[index].content = data.caption;
                    }
                })
                .catch((error) => {
                    console.error('Error fetching image details: ', error);
                });
        },

        storageFetchAudioDetails(data) {
            let dataFile;
            storageService
                .fetchAudioDetails(data.content)
                .then((audioFileInfo) => {
                    dataFile = [
                        {
                            audio: true,
                            duration: audioFileInfo.duration,
                            name: 'audio',
                            extension: 'audio/mp3',
                            size: audioFileInfo.size,
                            // type: audioFileInfo.type,
                            type: 'audio/mp3',
                            url: data.content,
                        },
                    ];

                    const index = this.messages.findIndex((element) => element._id === data.uuid);

                    if (index !== -1) {
                        this.messages[index].files = [...dataFile];
                    }
                })
                .catch((error) => {
                    console.error('Error fetching audio details: ', error);
                });
        },

        storageFetchDocumentDetails(data) {
            let dataFile;
            storageService
                .fetchDocumentDetails(data.content)
                .then((documentFileInfo) => {
                    dataFile = [
                        {
                            name: `Document`,
                            size: documentFileInfo.size,
                            type: documentFileInfo.type,
                            url: data.content,
                        },
                    ];

                    const index = this.messages.findIndex((element) => element._id === data.uuid);

                    if (index !== -1) {
                        this.messages[index].files = [...dataFile];
                    }
                })
                .catch((error) => {
                    console.error('Error fetching audio details: ', error);
                });
        },

        // One checkmark
        isSaved(message) {
            let isSaved;
            const status = message.channel_message_status ? message.channel_message_status : message.channel_status;
            switch (status) {
                case 'pending':
                case 'commited':
                case 'received':
                case 'sent':
                    isSaved = true;
                    break;
                case 'failed':
                    isSaved = false;
                    break;
                default:
                    isSaved = false;
                    break;
            }
            return isSaved;
        },

        // Two checkmarks
        isDistributed(message) {
            let isDistributed;
            const status = message.channel_message_status ? message.channel_message_status : message.channel_status;
            switch (status) {
                case 'delivered':
                    isDistributed = true;
                    break;

                case 'pending':
                case 'commited':
                case 'received':
                case 'sent':
                    isDistributed = false;
                    break;

                default:
                    isDistributed = true;
                    break;
            }

            return isDistributed;
        },

        // Two blue checkmarks
        isSeen(message) {
            let isSeen;
            const status = message.channel_message_status ? message.channel_message_status : message.channel_status;
            switch (status) {
                case 'read':
                    isSeen = true;
                    break;

                case 'delivered':
                case 'pending':
                case 'commited':
                case 'received':
                case 'sent':
                    isSeen = false;
                    break;

                default:
                    isSeen = false;
                    break;
            }

            return isSeen;
        },

        // Error icon
        isFailure(message) {
            let isFailure;
            const status = message.channel_message_status ? message.channel_message_status : message.channel_status;
            switch (status) {
                case 'failed':
                    isFailure = true;
                    break;

                case 'read':
                case 'delivered':
                case 'pending':
                case 'commited':
                case 'received':
                case 'sent':
                    isFailure = false;
                    break;

                default:
                    isFailure = false;
                    break;
            }

            return isFailure;
        },

        // Message deleted
        isDeleted(message) {
            let isDeleted;
            const status = message.channel_message_status ? message.channel_message_status : message.channel_status;
            switch (status) {
                case 'deleted':
                    isDeleted = true;
                    break;

                case 'failed':
                case 'read':
                case 'delivered':
                case 'pending':
                case 'commited':
                case 'received':
                case 'sent':
                    isDeleted = false;
                    break;

                default:
                    isDeleted = false;
                    break;
            }

            return isDeleted;
        },
        removeInitialTab(str) {
            // Regular expression to match a tab at the start of the string
            const regex = /^\s+/;
            const newStr = str.replace(regex, '');
            return newStr;
        },
        updateRoomId(newRoomId) {
            if (this.currentRoomId !== '') {
                this.unsubscribeToRoomMessages(this.currentRoomId);
            }

            this.resetMessages();
            this.roomId = newRoomId;
            this.updateCurrentUser(newRoomId);
            this.restartUnreadCount(newRoomId);
        },

        async obtainCustomerInfo(customerId) {
            try {
                const customer = await customerApiRepository.getCustomer(customerId);
                this.currentUserName = customer.customerFullName || customer.firstName || customer.lastName;
                this.currentUserEmail = customer.email;
                this.currentUserPhone = customer.phone;
            } catch (error) {
                console.error('Error fetching customer info: ', error);
            }
        },

        updateCurrentUser(roomId) {
            const item = this.rooms.find((item) => {
                return item.roomId === roomId;
            });
            if (item) {
                this.currentIdCustomer = item.users[1]._id;
                this.currentUserSatisfaction =
                    item.userSatisfaction !== 'UNDEFINED' ? item.userSatisfaction : 'NOT ANALYZED';
                this.obtainCustomerInfo(item.users[1]._id);

                // llamar al fetch de ordenes por cliente
                if (this.currentIdCustomer !== null) {
                    this.fetchAssets()
                        .then((assets) => {
                            this.customerOrders = assets;
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                            this.customerOrders = {};
                        });
                } else {
                    this.customerOrders = {};
                }
            }
        },
        restartUnreadCount(newRoomId) {
            const roomIndex = this.rooms.findIndex((room) => room.roomId === newRoomId);

            if (roomIndex !== -1 && this.rooms[roomIndex].unreadCount > 0) {
                try {
                    conversationsService.setConversationAsRead(newRoomId);
                } catch (error) {
                    console.error('Error marking conversation as read:', error);
                }
                this.rooms[roomIndex].unreadCount = 0;
            }
        },
        // Fetch de las ordenes del customer
        async fetchAssets() {
            this.loadingOrders = true;
            if (this.roomId === '' || this.currentIdCustomer === '') {
                console.error('Error: Conversation ID or Customer ID is invalid');
                return;
            }

            const conversationId = this.roomId;
            const customerId = this.currentIdCustomer;
            this.orders = {};

            try {
                const response = await conversationsService.fetchAssets(customerId, conversationId);
                const newOrders = response.data;
                this.orders = newOrders;

                this.loadingOrders = false;

                return this.orders;
            } catch (error) {
                this.loadingOrders = false;
                console.error('Error fetching orders: ', error);
            }
        },
        updateSendNewMenssage(newMessage) {
            this.sendAddMessage = newMessage;
        },
        async fetchStateAI(info) {
            if (!info) return;

            try {
                const aiEnabled = !info.ai_enabled;
                await conversationsService.updateStateAI(info.conversation, aiEnabled);

                this.stateAI = aiEnabled;
                // Update the attribute in the local component state
                this.findAndUpdateAttributeRooms('roomId', info.conversation, 'is_aion', aiEnabled);
            } catch (error) {
                console.error('Error fetching the state of the AI: ', error);
            }
        },
        findAndUpdateAttributeRooms(attribute, valueSearch, attributeUpdate, newValue) {
            for (let i = 0; i < this.rooms.length; i++) {
                if (this.rooms[i][attribute] === valueSearch) {
                    this.rooms[i][attributeUpdate] = newValue;
                    break;
                }
            }
        },

        customerInfo() {
            // Show or hide component
            if (this.enableInfoCustomer) {
                this.showCustomerDetails = !this.showCustomerDetails;
            }
        },
        async getNotesOrder(order) {
            if (!order.notes) {
                this.notes = null;
                return;
            }

            this.notes = order.notes;
        },
    },
    mounted() {
        if (this.isMobile) {
            this.showCustomerDetails = false;
        }
        if (this.activeFiltersState && Object.keys(this.activeFiltersState).length > 0) {
            if (this.activeFiltersState.agent) this.activeAgentFilter = this.activeFiltersState.agent;
            if (this.activeFiltersState.sentiment) this.activeSentimentFilter = this.activeFiltersState.sentiment;
            if (this.activeFiltersState.tags) this.activeTagsFilter = this.activeFiltersState.tags;
        }
        this.subscribeToRoomsList();
        this.fetchRooms();
    },
    beforeDestroy() {
        this.unsubscribeToRoomMessages(this.currentRoomId);
        this.unsubscribeToRoomsList();
    },
};
</script>

<style lang="scss" scoped>
body {
    font-family: 'Quicksand', sans-serif;
}

.custom-main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.custom-content-panel {
    // margin-bottom: 40px;
    padding-left: 0px;
    padding-right: 0px;

    @media (max-width: 1278px) {
        padding-left: 0px;
        padding-right: 0px;
    }

    @media (max-width: 959px) {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.custom-chat-panel {
    border-radius: 0px;
    border-right: none;
    border-left: none;
    border-bottom: none;

    // border-width: 0px;
    // overflow: hidden;
    box-shadow: none !important;

    @media (min-width: 959px) {
        border-top: none;
    }
}
</style>
