<script>
import { mapActions } from 'vuex';

export default {
    name: 'EditTags',
    props: {
        tags: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            localTags: [],
            isLoading: false,
        };
    },
    created() {
        this.localTags = [...this.tags];
    },
    computed: {
        canSave() {
            return JSON.stringify(this.localTags) !== JSON.stringify(this.tags);
        },
    },
    methods: {
        ...mapActions('modalStore', ['closeModal']),

        async handleSave() {
            this.isLoading = true;
            try {
                this.$emit('modalEvent', { eventName: 'update-tags', eventPayload: this.localTags });
                this.closeModal();
            } catch (error) {
                console.error('Error updating tags:', error);
                this.$toasted.error('Failed to update tags. Please try again.', {
                    position: 'bottom-center',
                    duration: 3000,
                });
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<template>
    <div class="edit-tags">
        <div class="edit-tags__header">
            <md-button @click="closeModal()" class="md-icon-button">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <md-content class="edit-tags__content">
            <div class="edit-tags__title-container">
                <span class="material-symbols-outlined edit-tags__icon">sell</span>
                <p class="edit-tags__title md-title">Edit Tags</p>
            </div>

            <div class="tags-form">
                <md-chips v-model="localTags" md-placeholder="Add tags...">
                    <label>Tags</label>
                </md-chips>
            </div>

            <div class="edit-tags__actions">
                <md-button @click="handleSave" class="md-raised md-primary" :disabled="isLoading || !canSave">
                    {{ isLoading ? 'Saving...' : 'Save Tags' }}
                </md-button>
                <md-button @click="closeModal()" class="md-gray md-outline">Cancel</md-button>
            </div>
        </md-content>
    </div>
</template>

<style lang="scss" scoped>
.edit-tags {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f5f5f5;
    width: 100%;
    max-width: 100%;

    @media (min-width: 768px) {
        width: 500px;
        max-width: 500px;
        margin: 0 auto;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        padding: 1rem 0.5rem 0 1rem;
        background-color: #ffffff;
    }

    &__title {
        font-size: 22px;
        font-weight: 500;
        margin: 0;
    }

    &__content {
        flex-grow: 1;
        padding: 0 2rem;
        overflow-y: auto;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
        margin-bottom: 1rem;
        gap: 16px;
    }

    &__title-container {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }

    &__icon {
        font-size: 24px;
        margin-right: 8px;
    }
}

.tags-form {
    margin-top: 1rem;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 1rem;
    border: 1px solid #e1e5e8;
}
</style>
