<template>
    <md-field class="sentiment-filter-field">
        <svg-icon class="filter-icon" :name="sentimentIcon(localSentimentFilter)" />
        <label for="sentiment-type">Sentiment</label>
        <md-select id="sentiment-type" v-model="localSentimentFilter">
            <md-option v-for="option in sentimentFilterOptions" :key="option" :value="option">
                {{ getSentimentFilterName(option) }}
            </md-option>
        </md-select>
    </md-field>
</template>

<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';

export default {
    name: 'SentimentFilter',
    components: { SvgIcon },

    props: {
        selectedSentiment: {
            type: String,
            default: 'ALL',
        },
    },

    data() {
        return {
            localSentimentFilter: 'ALL',
            sentimentFilterOptions: ['ALL', 'UNDEFINED', 'POSITIVE', 'NEGATIVE'],
        };
    },

    watch: {
        selectedSentiment: {
            immediate: true,
            handler(newValue) {
                this.localSentimentFilter = newValue;
            },
        },
        localSentimentFilter() {
            this.$emit('sentiment-filter-changed', this.localSentimentFilter);
        },
    },

    methods: {
        getSentimentFilterName(sentiment) {
            if (sentiment === 'ALL') return 'All Sentiments';
            if (sentiment === 'UNDEFINED') return 'Not Analyzed';
            return sentiment
                .toLowerCase()
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        },

        sentimentIcon(sentiment) {
            if (sentiment === 'UNDEFINED') return 'NOT_ANALYZED';
            if (sentiment === 'ALL') return 'FILTER_ALL';
            return sentiment;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.sentiment-filter-field {
    display: flex;
    align-items: center;
}

.filter-icon {
    height: 15px;
    width: 15px;
    margin-right: 8px;
    fill: $mdb-text-color-primary;
}
</style>
